import GetAddressRepostiory from "./GetAddressRepostiory";

const apiKey = "P6JTU52clKYjZca8";
export default {
    getAddress(postcode, houseNumber) {
        return GetAddressRepostiory.get(
            `streetNumberAndPremise?authKey=${apiKey}&postalCode=${postcode}&streetNumberAndPremise=${houseNumber}`
        );
    },
};
