import { RepositoryFactory } from "../../repository/RepositoryFactory";
var address = RepositoryFactory.get("address");
var leads = RepositoryFactory.get("leads");

export const getAddress =
    (postalCode, houseNumber, onSuccess, onError) => async (dispatch) => {
        dispatch({
            type: "LOADER",
            payload: true,
        });
        try {
            let { data } = await address.getAddress(postalCode, houseNumber);

            if (data) {
                onSuccess(data[0]);
            } else {
                onError();
            }
        } catch (e) {
            console.error(e.response?.data?.error || "An error occurred");
            onError();
        } finally {
            dispatch({
                type: "LOADER",
                payload: false,
            });
        }
    };

export const postLead =
    (payload, onSuccess = () => {}) =>
    async (dispatch) => {
        dispatch({
            type: "LOADER",
            payload: true,
        });
        try {
            let { data } = await leads.postLead(payload);

            if (data) {
                await dispatch({
                    type: "CREATE_RESPONSE",
                    payload: data,
                });
                await dispatch({
                    type: "LOADER",
                    payload: false,
                });
                onSuccess();
                //  alert('ThankYou');
            } else {
                dispatch({
                    type: "LOADER",
                    payload: false,
                });
                dispatch({
                    type: "CREATE_RESPONSE",
                    payload: {},
                });
            }
        } catch (e) {
            if (e.response.data.error === "Conflict - duplicate") {
                await dispatch({
                    type: "CREATE_RESPONSE",
                    payload: {},
                });
                dispatch({
                    type: "LOADER",
                    payload: false,
                });
                onSuccess();
            } else {
                console.error(e.response.data.error);
                dispatch({
                    type: "LOADER",
                    payload: false,
                });
                alert(e.response.data.error);
                dispatch({
                    type: "CREATE_RESPONSE",
                    payload: {},
                });
            }
        } finally {
            dispatch({
                type: "LOADER",
                payload: false,
            });
        }
    };
