import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function TermsAndConditionModal({ termsAndConditionIsOpen, termsToggleModal }) {
    return (
        <Modal
            isOpen={termsAndConditionIsOpen}
            toggle={termsToggleModal}
            centered
            size="lg"
            className="bg-white"
        >
            <ModalHeader toggle={termsToggleModal}>
                <h3 className="mb-3">Algemene voorwaarden</h3>
            </ModalHeader>
            <ModalBody className="modal-scrollable">
                {/* <iframe src="https://docs.google.com/viewerng/viewer?url=${https://firebasestorage.googleapis.com/v0/b/republish-react.appspot.com/o/Algemene%20Voorwaa[…]?alt=media&token=90229f62-718f-432b-ab94-1b831c937ae1}&embedded=true"></iframe> */}
                <div className="scrollable-content">
                    <h2>
                        ALGEMENE VOORWAARDEN DE ENERGIEBESPAARDERS, VERSIE MAART
                        2023
                    </h2>
                    <h3>In deze voorwaarden wordt verstaan onder:</h3>
                    <ul>
                        <li>
                            <strong>Ons/wij:</strong> De Energiebespaarders
                        </li>
                        <li>
                            <strong>Opdrachtgever:</strong> degene die door
                            ondertekening van een geschrift of op andere wijze
                            de gelding van deze algemene voorwaarden heeft
                            aanvaard.
                        </li>
                        <li>
                            <strong>Installateur:</strong> de partner
                            installateur welke door De Energiebespaarders wordt
                            ingeschakeld om het werk uit te voeren.
                        </li>
                        <li>
                            <strong>
                                De genoemde bedragen zijn inclusief BTW
                            </strong>
                        </li>
                    </ul>
                    <h3>ARTIKEL 1 - ALGEMEEN</h3>
                    <h4>Toepasselijkheid</h4>
                    <ol>
                        <li>
                            Deze algemene voorwaarden zijn van toepassing op de
                            inhoud, totstandkoming en nakoming van al onze
                            transacties en /of tussen partijen gebruikelijke
                            condities. Bedingen, met name bijzondere, waarbij
                            van deze algemene voorwaarden wordt afgeweken, zijn
                            slechts van kracht voor zover zij door ons
                            schriftelijk zijn bevestigd.
                        </li>
                        <li>
                            Met inachtneming van het vorenstaande wordt de
                            toepasselijkheid van door Opdrachtgever gehanteerde
                            voorwaarden uitdrukkelijk uitgesloten.
                        </li>
                        <li>
                            In ieder geval gelden deze voorwaarden te allen
                            tijde in aanvulling op hetgeen partijen zijn
                            overeengekomen.
                        </li>
                    </ol>
                    <h3>ARTIKEL 2 - AANBOD VAN DE ENERGIEBESPAARDERS</h3>
                    <ol>
                        <li>
                            Het aanbod voor werk wordt schriftelijk of langs
                            elektronische weg gedaan, tenzij spoedeisende
                            omstandigheden dit onmogelijk maken. Het aanbod
                            wordt voorzien van een dagtekening. Op ieder aanbod
                            zijn de volgende leden van dit artikel van
                            toepassing.
                        </li>
                        <li>
                            Het aanbod omvat een omschrijving van de te
                            verrichten werkzaamheden en de te leveren
                            materialen, die voldoende gedetailleerd is om een
                            goede beoordeling van het aanbod door Opdrachtgever
                            mogelijk te maken.
                        </li>
                        <li>
                            Indien het aanbod een stelpost bevat, dan wordt
                            duidelijk omschreven welke artikelen in deze
                            stelpost zijn begroot.
                        </li>
                        <li>
                            Het aanbod gaat vergezeld van een exemplaar van deze
                            algemene voorwaarden.
                        </li>
                    </ol>
                    <h3>ARTIKEL 3 - OVEREENKOMSTEN</h3>
                    <ol>
                        <li>
                            Al onze aanbiedingen en offertes zijn vrijblijvend
                            ook indien daarin een termijn wordt genoemd, tenzij
                            uitdrukkelijk het tegendeel is vermeld.
                        </li>
                        <li>
                            De in de overeenkomst genoemde aantallen (e.g.
                            strekkende meters, vierkante meters en andere
                            eenheden) zijn gebaseerd op de beschikbare
                            informatie en zijn niet achteraf verrekenbaar door
                            Partijen.
                        </li>
                        <li>
                            De overeenkomst komt tot stand door aanvaarding door
                            Opdrachtgever. De aanvaarding gebeurt bij voorkeur
                            en waar mogelijk langs schriftelijke of
                            elektronische weg. In geval van elektronische
                            opdrachtverlening door Opdrachtgever bevestigt
                            Installateur langs elektronische weg de ontvangst
                            van de opdracht aan Opdrachtgever.
                        </li>
                        <li>
                            Opdrachtgever is aan een schriftelijke of
                            elektronische bevestiging onzerzijds gebonden,
                            tenzij Opdrachtgever, binnen 5 werkdagen na
                            ontvangst van de bevestiging, de juistheid van deze
                            bevestiging betwist.
                        </li>
                        <li>
                            Indien een aanbieding wordt aanvaard door
                            Opdrachtgever, hebben wij het recht de aanbieding
                            binnen vijf werkdagen na ontvangst van de
                            aanvaarding te herroepen.
                        </li>
                        <li>
                            Indien Opdrachtgever redelijkerwijs kan begrijpen
                            dat de offertes of een onderdeel daarvan een
                            kennelijke vergissing of verschrijving bevat, kunnen
                            wij niet aan de offerte worden gehouden.
                        </li>
                        <li>
                            Er kunnen geen rechten worden ontleend aan eventuele
                            typefouten in de aanbieding.
                        </li>
                        <li>
                            Indien de aanvaarding van de offerte afwijkt van het
                            in de offerte opgenomen aanbod, dan zijn wij daaraan
                            niet gebonden. De overeenkomst komt dan
                            overeenkomstig de offerte tot stand met uitzondering
                            van de afwijkingen van het aanbod.
                        </li>
                        <li>
                            Indien tijdens de uitvoering van de overeenkomst
                            blijkt dat het noodzakelijk is om de overeenkomst te
                            wijzigen of aan te vullen, dan dienen de partijen
                            tijdig en in onderling overleg tot aanpassing van de
                            overeenkomst over te gaan. Dit kan schriftelijk of
                            mondeling. Hierdoor kan het overeengekomen bedrag
                            volgens de overeenkomst worden verhoogd of verlaagd.
                            Door de wijziging van de overeenkomst kan de
                            oorspronkelijke opgegeven datum of termijn van
                            uitvoering (indien overeengekomen) worden gewijzigd.
                            De opdrachtgever gaat akkoord met de mogelijkheid
                            van wijziging van de overeenkomst, daaronder
                            begrepen de wijziging in prijs- en datumafspraak van
                            uitvoering.
                        </li>
                        <li>
                            Opdrachten van Opdrachtgever die leiden tot meerwerk
                            vinden in onderling overleg plaats. Dit kan
                            schriftelijk of mondeling, maar dient schriftelijk
                            aan ons te worden bevestigd.
                        </li>
                        <li>
                            Indien de overeenkomst is gewijzigd dienen wij
                            daaraan uitvoering te geven, nadat Opdrachtgever
                            akkoord is gegaan met de voor uitvoering opgegeven
                            prijs en andere voorwaarden. Het niet of niet
                            onmiddellijk uitvoeren van de gewijzigde
                            overeenkomst levert evenmin een wanprestatie op van
                            ons en is voor de opdrachtgever geen grond om de
                            overeenkomst op te zeggen.
                        </li>
                        <li>
                            Indien en voor zover wij tijdens het uitvoeren van
                            de overeenkomst geconfronteerd worden met voor ons
                            onvoorzienbare kostenverhogende factoren, zijn wij
                            na waarschuwing dan wel in onderling overleg met
                            Opdrachtgever gerechtigd de kostenverhogende
                            factoren door te berekenen aan Opdrachtgever.
                        </li>
                        <li>
                            Indien na totstandkoming van de overeenkomst blijkt
                            dat het terrein verontreinigd is of de uit het werk
                            komende bouwstoffen verontreinigd zijn, is de
                            opdrachtgever aansprakelijk voor de daaruit voor de
                            uitvoering van het werk voortvloeiende gevolgen.
                        </li>
                        <li>
                            Indien na het sluiten van de overeenkomst de prijzen
                            van arbeid, materialen, belastingen en/of andere
                            factoren die de prijs van de goederen en/of diensten
                            mede bepalen, een wijziging ondergaan, is DEB
                            gerechtigd deze prijswijzigingen door te voeren.
                            Prijswijzigingen van meer dan 10% geven wederpartij
                            het recht om de overeenkomst te ontbinden, mits dit
                            schriftelijk en binnen zeven dagen na ontvangst van
                            de desbetreffende mededeling gebeurt. Een ontbinding
                            als voormeld geeft wederpartij geen recht op
                            vergoeding van enigerlei schade.
                        </li>
                    </ol>
                    <h3>ARTIKEL 4 - INSTALLATIE DATUM</h3>
                    <ol>
                        <li>
                            De datum van installatie geldt niet als fatale
                            datum, zodat wij bij overschrijding niet in gebreke
                            kunnen worden gesteld. Verzuim wegens overschrijding
                            van de installatiedatum zal de opdrachtgever nimmer
                            het recht geven op schadevergoeding noch op
                            ontbinding van de transactie.
                        </li>
                        <li>
                            Wij hebben het recht de datum van installatie aan te
                            passen, indien niet tijdig alle voor de uitvoering
                            vereiste gegevens door ons zijn ontvangen.
                        </li>
                    </ol>
                    <h3>ARTIKEL 5 - AANSPRAKELIJKHEID</h3>
                    <ol>
                        <li>
                            Indien wij aansprakelijk zijn, dan is deze
                            aansprakelijkheid beperkt tot hetgeen in deze
                            bepaling is geregeld.
                        </li>
                        <li>
                            Wij zijn niet aansprakelijk voor schade, van welke
                            aard ook, ontstaan doordat wij zijn uitgegaan van
                            door of namens Opdrachtgever verstrekte onjuiste
                            en/of onvolledige gegevens.
                        </li>
                        <li>
                            Wij zijn niet aansprakelijk voor de schade
                            aangebracht door Installateur. Deze
                            aansprakelijkheid ligt bij de Installateur, zoals
                            contractueel vastgelegd is in de
                            samenwerkingsovereenkomst tussen ons en
                            Installateur.
                        </li>
                        <li>
                            Installateur is uitsluitend aansprakelijk voor
                            directe schade. Onder directe schade wordt
                            uitsluitend verstaan: de redelijke kosten ter
                            vaststelling van de oorzaak en de omvang van de
                            schade, voor zover de vaststelling betrekking heeft
                            op schade in de zin van deze voorwaarden; de
                            eventuele redelijke kosten gemaakt om de gebrekkige
                            prestatie van Installateur aan de overeenkomst te
                            laten beantwoorden, voor zoveel deze aan
                            Installateur toegerekend kunnen worden; redelijke
                            kosten, gemaakt ter voorkoming of beperking van
                            schade, voor zover de opdrachtgever aantoont dat
                            deze kosten hebben geleid tot beperking van directe
                            schade als bedoeld in deze algemene voorwaarden.
                        </li>
                        <li>
                            Wij of Installateur zijn nimmer aansprakelijk voor
                            indirecte schade, daaronder begrepen gevolgschade,
                            gederfde winst, gemiste besparingen en schade door
                            bedrijfs- of andersoortige stagnatie.
                        </li>
                        <li>
                            De aansprakelijkheid van Installateur is in ieder
                            geval steeds beperkt tot het bedrag der uitkering
                            van zijn verzekeraar in voorkomend geval.
                        </li>
                        <li>
                            De in dit geval opgenomen beperkingen van de
                            aansprakelijkheid gelden niet indien de schade te
                            wijten is aan opzet of grove schuld van ons of van
                            Installateur.
                        </li>
                        <li>
                            Wij aanvaarden geen enkele aansprakelijkheid in
                            geval van niet, niet tijdige, niet volledige of niet
                            goede nakoming en onverminderd ons recht alsnog na
                            te komen hetgeen door Opdrachtgever is bedongen,
                            voor zover de wet zulks toelaat.
                        </li>
                        <li>
                            Evenmin zijn wij of Installateur aansprakelijk voor
                            schade als gevolg van constructiefouten of gebreken
                            aan het pand. Daaronder worden begrepen foutief
                            aangebrachte ontluchtingen en ontluchtingsroosters,
                            kieren bij kozijnen en sponningen, rookkanalen met
                            open verbinding naar een spouw, reeds aanwezige
                            vochtproblemen, lekkages, constructiefouten aan het
                            dak of andere constructiedelen.
                        </li>
                        <li>
                            Aansprakelijkheid voor vervolgschade, waaronder
                            vorstschade, wordt uitgesloten.
                        </li>
                        <li>
                            Schade ontstaan door aan of ten gevolge van
                            goederen, die door ons van een andere leverancier
                            zijn betrokkenen of diensten, die door ons zijn
                            uitbesteed, wordt slechts vergoed, indien en voor
                            zoveel de schade door de desbetreffende leverancier
                            aan ons is vergoed en wij deze vergoeding hebben
                            ontvangen, onder aftrek van de daarbij gemaakte
                            kosten.
                        </li>
                        <li>
                            Wij zullen niet aansprakelijk zijn voor hetgeen,
                            waartoe de Opdrachtgever jegens derden zal worden
                            gehouden, anders dan voor onze rechtstreekse
                            gehoudenheid jegens Opdrachtgever als voortvloeiend
                            uit deze voorwaarden. Opdrachtgever zal ons ter zake
                            vrijwaren.
                        </li>
                        <li>
                            Opdrachtgever verklaart dat er geen asbest aanwezig
                            is op de plaats waar het product wordt geplaatst.
                        </li>
                    </ol>
                    <h3>ARTIKEL 6 - ANNULERING</h3>
                    <ol>
                        <li>
                            Annulering door Opdrachtgever dient schriftelijk aan
                            ons te geschieden.
                        </li>
                        <li>
                            Indien een aanbetaling is gedaan, zal deze bij
                            annulering van de opdracht door Opdrachtgever niet
                            worden gerestitueerd.
                        </li>
                        <li>
                            Indien Opdrachtgever een opdracht wenst te
                            annuleren, worden bij opdrachtgever kosten in
                            rekening gebracht volgens onderstaand schema:
                        </li>
                    </ol>
                    <ul>
                        <li>
                            €250,- bij ontvangst van de annulering langer dan 2
                            weken voor aanvang van de installatie of indien er
                            nog geen installatiedatum is vastgelegd;
                        </li>
                        <li>
                            €375,- bij ontvangst van de annulering binnen 2
                            weken voor aanvang van de installatie;
                        </li>
                        <li>
                            €500,- bij ontvangst binnen 48 uur voor aanvang van
                            de installatie;
                        </li>
                    </ul>
                    <h3>ARTIKEL 7 - OVERMACHT</h3>
                    <p>
                        In geval van overmacht, althans in de gevallen welke in
                        deze voorwaarden als overmacht worden omschreven, zijn
                        wij ontslagen van onze contractverplichtingen jegens
                        Opdrachtgever. Onder overmacht zijn begrepen alle
                        omstandigheden, welke redelijkerwijze geacht moeten
                        worden de nakoming en/of tijdige nakoming van de
                        verplichtingen uit transacties in de weg te staan, zoals
                        brand, werkstaking of uitsluiting, oorlog, mobilisatie,
                        afkondiging van de staat van oorlog of beleg, oproer,
                        maatregelen van overheidswege, waardoor de nakoming
                        wordt verboden of belemmerd, niet-nakoming van onze
                        leverancier. Althans niet nakoming onder de met ons
                        afgesproken condities, watersnood, bedrijfsstoring zowel
                        in onze eigen onderneming als in ondernemingen waarvan
                        wij materialen of diensten betrekken of waar wij
                        materialen laten bewerken, of andere omstandigheden,
                        welke de normale bedrijfsuitoefening onmogelijk maken
                        zowel in Nederland als in het land van herkomst en/of
                        doorvoer van de grondstoffen en/of materialen.
                    </p>
                    <h3>ARTIKEL 8 - UITVOER</h3>
                    <ol>
                        <li>
                            Opdrachtgever zorgt ervoor dat wij tijdig kunnen
                            beschikken over tekeningen en andere gegevens die
                            voor het verrichten van de werkzaamheden
                            noodzakelijk zijn. Zo dient Opdrachtgever, indien
                            het werk daartoe aanleiding geeft, ons in te lichten
                            omtrent de aanwezigheid en de juiste ligging van de
                            zich in of nabij het werk bevindende kabels,
                            leidingen en/of afvoeren. Verder dient Opdrachtgever
                            ons te informeren indien de kruipruimte niet droog
                            is en indien de kruipruimte minder dan 50 cm hoog
                            is. Daarnaast dient Opdrachtgever ons te informeren
                            indien andere ruimtes waarin gewerkt moet worden
                            niet bereikbaar zijn.
                        </li>
                        <li>
                            De voorzieningen die nodig zijn voor de uitvoering
                            van de werkzaamheden komen voor rekening en risico
                            van Opdrachtgever, ook indien deze worden uitgevoerd
                            volgens onze aanwijzingen, maar niet door ons.
                            Opdrachtgever zorgt voor eigen rekening en risico
                            dat de Installateur in de gelegenheid wordt gesteld
                            de werkzaamheden uit te voeren. Tenzij anders
                            overeengekomen, dient de opdrachtgever te zorgen
                            voor het afplakken van onderdelen die niet behandeld
                            dienen te worden. Verder dient Opdrachtgever bij
                            werkzaamheden in de open lucht kosteloos zorg te
                            dragen voor verkeers- en andere voorzieningen,
                            waardoor de werkzaamheden zonder schade voor derden
                            kunnen plaatsvinden.
                        </li>
                        <li>
                            Opdrachtgever verstrekt kosteloos met inachtneming
                            van de noodzakelijke veiligheidsvoorschriften en
                            andere voorzorgsmaatregelen de nodige hulpmiddelen
                            en verleent, hetzij persoonlijk, hetzij door
                            zijnerzijds beschikbaar gestelde hulp werkkrachten,
                            de nodige bijstand.
                        </li>
                    </ol>
                    <h3>
                        ARTIKEL 9 - BEËINDIGING VAN HET WERK IN ONVOLTOOIDE
                        STAAT
                    </h3>
                    <ol>
                        <li>
                            Opdrachtgever kan de uitvoering van het werk te
                            allen tijde geheel of gedeeltelijk beëindigen.
                        </li>
                        <li>
                            Partijen zullen over en weer meewerken aan een
                            gezamenlijke vastlegging van de feitelijke toestand
                            van het werk op het moment van oplevering in
                            onvoltooide staat.
                        </li>
                        <li>
                            Tot op het moment van oplevering in onvoltooide
                            staat is Installateur verplicht, tegen vergoeding,
                            maatregelen te treffen ter voorkoming en beperking
                            van de schade.
                        </li>
                        <li>
                            Opdrachtgever zal Installateur de hem toekomende
                            aanneemsom vergoeden, vermeerderd met de kosten van
                            de in lid 3 bedoelde vergoeding(en) alsook met
                            andere door de beëindiging
                        </li>
                    </ol>
                    <h3>ARTIKEL 10 - MEER- EN MINDERWERK</h3>
                    <ol>
                        <li>
                            Als het totaal aan te verrekenen minderwerk het
                            totaal aan te verrekenen meerwerk overtreft, heeft
                            Installateur recht op een bedrag gelijk aan 10% van
                            het verschil van deze totalen. Dit geldt niet voor
                            zover het minderwerk het gevolg is van een verzoek
                            van Installateur om minder te mogen uitvoeren dan in
                            de overeenkomst was bepaald.
                        </li>
                        <li>
                            In geval van door de opdrachtgever opgedragen
                            meerwerk kunnen wij alleen aanspraak maken op een
                            verhoging van de prijs, indien Installateur
                            Opdrachtgever tijdig heeft gewezen op de daaruit
                            voortvloeiende prijsverhoging, tenzij Opdrachtgever
                            dit zelf reeds had moeten begrijpen.
                        </li>
                        <li>
                            Meer- en minderwerk voor een bedrag van meer dan €
                            300 wordt direct bij het maken van de afspraken
                            schriftelijk vastgelegd, behalve in spoedeisende
                            omstandigheden.
                        </li>
                        <li>
                            Het ontbreken van een document laat de aanspraken
                            van Installateur resp. Opdrachtgever op verrekening
                            van meerwerk resp. minderwerk onverlet. In dat geval
                            rust het bewijs van de overeenkomst op degene die de
                            aanspraak maakt.
                        </li>
                    </ol>
                    <h3>ARTIKEL 11 - ONVOORZIENE OMSTANDIGHEDEN</h3>
                    <ol>
                        <li>
                            Wanneer zich onvoorziene omstandigheden voordoen,
                            meldt Installateur dat zo spoedig mogelijk aan
                            Opdrachtgever en onderbreekt Installateur voor zover
                            de onvoorziene omstandigheid dat nodig maakt zijn
                            werkzaamheden, behalve voor zover de onvoorziene
                            omstandigheid onmiddellijk handelen vereist.
                        </li>
                        <li>
                            Eventuele extra kosten die Installateur moet maken
                            in verband met een onvoorziene omstandigheid die
                            onmiddellijk handelen vereist en die redelijk zijn
                            ter beperking van de schade, zullen door
                            Opdrachtgever worden vergoed.
                        </li>
                        <li>
                            Indien de onvoorziene omstandigheid niet
                            onmiddellijk handelen vereist, dan zullen partijen
                            gezamenlijk de verdere uitvoering van het werk
                            bepalen.
                        </li>
                    </ol>
                    <h3>ARTIKEL 12 - VERPLICHTINGEN DE ENERGIEBESPAARDERS</h3>
                    <ol>
                        <li>
                            Wij zijn verplicht het opgedragen werk naar de
                            bepalingen van de (gewijzigde) overeenkomst uit te
                            voeren.
                        </li>
                        <li>
                            Wij aanvaarden de overeenkomst onder het voorbehoud
                            dat de met betrekking tot de opdracht vereiste
                            vergunningen, ontheffingen en toewijzingen tijdig
                            (dat wil zeggen voordat door Installateur aan de
                            opdracht dient te worden begonnen) zijn verleend aan
                            Opdrachtgever.
                        </li>
                        <li>
                            Wij verplichten ons Opdrachtgever te wijzen op
                            aantoonbare onvolkomenheden in door of namens
                            Opdrachtgever voorgeschreven constructies en
                            werkwijzen en in door of namens Opdrachtgever
                            gegeven orders of aanwijzingen, alsmede op
                            aantoonbare gebreken in door de opdrachtgever ter
                            beschikking gestelde of voorgeschreven bouwstoffen
                            of hulpmiddelen, voor zover wij of Installateur deze
                            kenden of redelijkerwijs behoorden te kennen.
                        </li>
                        <li>
                            Installateur zal het werk goed, deugdelijk en naar
                            de bepalingen van de overeenkomst uitvoeren. De
                            werkzaamheden worden verricht binnen de normale
                            werktijden van Installateur, tenzij anders
                            overeengekomen.
                        </li>
                        <li>
                            Indien de opdracht van Opdrachtgever aan
                            Installateur strekt tot het verlenen van service, is
                            Installateur verplicht om zich in te spannen om de
                            oorzaak van het gebrek op te sporen en vervolgens de
                            oorzaak van het gebrek te verhelpen.
                        </li>
                        <li>
                            Installateur neemt bij de uitvoering van het werk de
                            daarop van toepassing zijnde voorschriften in acht,
                            zoals deze van kracht zijn of zullen zijn ten tijde
                            van de uitvoering van het werk.
                        </li>
                        <li>
                            Een verhoging van de prijs als gevolg van wijziging
                            van de voorschriften mag aan Opdrachtgever worden
                            doorberekend, mits wij aantonen dat wij op het
                            moment van sluiten van de overeenkomst niet van de
                            wijziging op de hoogte konden zijn.
                        </li>
                        <li>
                            Installateur is verplicht Opdrachtgever te wijzen op
                            de op het eerste gezicht kenbare en voor het werk
                            relevante: onjuistheden in de opgedragen
                            werkzaamheden; onjuistheden in de door Opdrachtgever
                            verlangde werkwijzen en constructies; gebreken van
                            de (on)roerende zaak waaraan het werk wordt
                            verricht; (bodem)verontreiniging, milieubelastende
                            stoffen en/of bacteriën (zoals bijvoorbeeld asbest
                            en legionella) in of bij de (on)roerende zaak
                            waaraan het werk wordt verricht; gebreken in of
                            ongeschiktheid van materialen of hulpmiddelen die
                            door de opdrachtgever ter beschikking zijn gesteld;
                            onjuistheden in de door of namens de opdrachtgever
                            verstrekte gegevens; al het voorgaande voor zover
                            deze zich voor of tijdens de uitvoering van het werk
                            aan Installateur openbaren en Installateur terzake
                            deskundig moet worden geacht.
                        </li>
                    </ol>
                    <h3>ARTIKEL 13 - VERPLICHTINGEN OPDRACHTGEVER</h3>
                    <ol>
                        <li>
                            Opdrachtgever stelt Installateur in de gelegenheid
                            het werk te verrichten.
                        </li>
                        <li>
                            Opdrachtgever dient, tenzij anders is
                            overeengekomen, ervoor zorg te dragen dat wij of
                            Installateur tijdig, voor aanvang van de uitvoering
                            van de werkzaamheden, de beschikking hebben over:
                            <ul>
                                <li>
                                    alle met de opdracht vereiste vergunningen,
                                    ontheffingen en toewijzingen;
                                </li>
                                <li>
                                    de locatie, waar de werkzaamheden uitgevoerd
                                    moeten worden, ontruimd is;
                                </li>
                                <li>
                                    voldoende gelegenheid is voor aanvoer,
                                    opslag en/ of afvoer van bouwmaterialen en
                                    werktuigen;
                                </li>
                                <li>
                                    voldoende aansluitmogelijkheden voor onder
                                    andere elektrische werktuigen, aftappunten
                                    voor gas en water;
                                </li>
                                <li>
                                    alle gegevens aangaande het bevinden van
                                    kabels, leidingen en buizen in, op, aan of
                                    onder de bouwlocatie of bouwperceel.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Opdrachtgever is verplicht, zonder aanspraak op
                            vergoeding, water, gas, elektra en opslagruimte voor
                            materialen en werktuigen ter beschikking te stellen,
                            indien deze op of bij de locatie aanwezig zijn.
                        </li>
                        <li>
                            Indien Opdrachtgever de levering van bepaalde
                            bouwmaterialen en/ of de uitvoering van bepaalde
                            werkzaamheden aan zich heeft voorbehouden, is
                            Opdrachtgever verantwoordelijk en aansprakelijk voor
                            het tijdige aanvoer en/ of uitvoering hiervan.
                        </li>
                        <li>
                            Indien bij aanvang of bij de voortgang van de
                            overeengekomen opdracht vertraging optreedt door
                            verantwoordelijkheid van Opdrachtgever is
                            Opdrachtgever aansprakelijk voor de daaruit
                            voortvloeiende schade en dient Opdrachtgever uitstel
                            te verlenen voor de oplevering van de overeengekomen
                            werkzaamheden voor een periode die ten minste gelijk
                            is aan de duur van de ontstane vertraging.
                        </li>
                        <li>
                            Opdrachtgever dient ervoor te zorgen dat door derden
                            uit te voeren werkzaamheden en/of leveringen, die
                            niet tot het werk van Installateur behoren, zodanig
                            en zo tijdig worden verricht dat de uitvoering van
                            het werk daarvan geen vertraging ondervindt. Indien
                            niettemin vertraging in de zin van dit lid ontstaat,
                            dient Opdrachtgever Installateur hiervan tijdig in
                            kennis te stellen.
                        </li>
                        <li>
                            In geval van een tekortkoming van Installateur bij
                            de uitvoering van de overeenkomst wijst de
                            Opdrachtgever Installateur daar zo spoedig mogelijk
                            schriftelijk op, met een duidelijke omschrijving van
                            de tekortkoming en met een redelijke termijn om de
                            tekortkoming te herstellen, tenzij Installateur
                            reeds door de enkele tekortkoming in verzuim is.
                        </li>
                    </ol>
                    <h3>ARTIKEL 14 - UITVOER</h3>
                    <ol>
                        <li>
                            Het werk is uitgevoerd wanneer Installateur aan
                            Opdrachtgever heeft meegedeeld dat het werk voltooid
                            is en Opdrachtgever het werk heeft aanvaard.
                        </li>
                        <li>
                            Het werk wordt als uitgevoerd beschouwd:
                            <ul>
                                <li>
                                    hetzij wanneer 8 dagen zijn verstreken nadat
                                    Opdrachtgever van Installateur de mededeling
                                    heeft ontvangen dat het werk is voltooid en
                                    Opdrachtgever heeft nagelaten het werk te
                                    aanvaarden dan wel af te keuren;
                                </li>
                                <li>
                                    hetzij wanneer Opdrachtgever de installatie
                                    (opnieuw) in gebruik neemt, met dien
                                    verstande dat door ingebruikneming van een
                                    gedeelte van het werk dat gedeelte als
                                    uitgevoerd wordt beschouwd, tenzij het aan
                                    ingebruikneming verbonden gevolg
                                    (oplevering) niet gerechtvaardigd is.
                                </li>
                            </ul>
                        </li>
                    </ol>

                    <h3>ARTIKEL 15 - VOORUITBETALING/ZEKERHEID</h3>
                    <ol>
                        <li>
                            Wij zijn gerechtigd bij een opdracht een
                            vooruitbetaling te verlangen. In het geval een
                            bestelling van materialen op maat gedaan moet
                            worden, zal altijd een aanbetaling gevraagd worden.
                        </li>
                        <li>
                            Wij kunnen bij het sluiten van de overeenkomst
                            zekerheid bedingen van Opdrachtgever.
                        </li>
                        <li>
                            Na het sluiten van de overeenkomst kunnen wij
                            zekerheid bedingen indien wij goede grond hebben te
                            vrezen dat de opdrachtgever zijn
                            betalingsverplichting niet zal nakomen. Indien en
                            zolang Opdrachtgever in dat geval weigert of niet in
                            staat is zekerheid te stellen, zijn wij gerechtigd
                            de uitvoering van het werk te onderbreken, voor
                            zover dit gerechtvaardigd is.
                        </li>
                    </ol>

                    <h3>ARTIKEL 16 - NIET NAKOMEN BETALINGSVERPLICHTING</h3>
                    <ol>
                        <li>
                            Indien Opdrachtgever niet tijdig betaalt, wordt hij
                            wettelijk geacht zonder enige ingebrekestelling in
                            verzuim te verkeren. Niettemin zenden wij na het
                            verstrijken van de betalingsdatum één
                            betalingsherinnering, waarin wij Opdrachtgever op
                            zijn verzuim wijzen en hem alsnog de gelegenheid
                            geven binnen 15 dagen na ontvangst van deze
                            betalingsherinnering te betalen.
                        </li>
                        <li>
                            Over de betaling die niet tijdig is verricht, kunnen
                            wij rente in rekening brengen vanaf het verstrijken
                            van de betalingsdatum tot de dag van ontvangst van
                            het verschuldigde. Deze rente is gelijk aan de
                            wettelijke rente ingevolge artikel 6:119 van het
                            Burgerlijk Wetboek.
                        </li>
                        <li>
                            Wij zijn na verloop van de in lid 1 bedoelde termijn
                            van 15 dagen bevoegd zonder nadere ingebrekestelling
                            tot invordering over te gaan van het aan hem
                            verschuldigde bedrag. Indien wij hiertoe overgaan,
                            zijn de daaraan verbonden buitengerechtelijke kosten
                            voor rekening van Opdrachtgever. De hoogte van deze
                            kosten is onderworpen aan wettelijke grenzen.
                            Daarvan kan in het voordeel van Opdrachtgever worden
                            afgeweken.
                        </li>
                        <li>
                            Wij blijven eigenaar van verwerkte en nog niet
                            verwerkte materialen, totdat Opdrachtgever aan zijn
                            betalingsverplichting heeft voldaan.
                        </li>
                    </ol>

                    <h3>ARTIKEL 17 - KOSTEN</h3>
                    <p>
                        Alle eventuele kosten, waaronder incasso-, deurwaarders-
                        en advocaatkosten, zowel gerechtelijk als
                        buitengerechtelijk, zijn begrepen, welke door ons worden
                        gemaakt teneinde nakoming van de verplichtingen van
                        Opdrachtgever te bewerkstelligen, zijn ten laste van
                        Opdrachtgever. De buitengerechtelijke kosten belopen 15%
                        van de vordering, met een minimum van € 250,-,
                        waaromtrent geen bewijs behoeft te worden aangevoerd,
                        onverminderd ons recht op nakoming en verdere vergoeding
                        van de schade; zij zijn verschuldigd vanaf het moment
                        waarop de vordering in handen van onze advocaat is
                        gesteld ongeacht of Opdrachtgever daarvan op de hoogte
                        is.
                    </p>
                    <h3>ARTIKEL 18 - OPSCHORTING VAN BETALING</h3>
                    <ol>
                        <li>
                            Indien het opgeleverde werk niet voldoet aan de
                            overeenkomst, heeft de Opdrachtgever het recht de
                            betaling op te schorten, met dien verstande dat het
                            op te schorten bedrag in een redelijke verhouding
                            dient te staan tot het geconstateerde gebrek.
                        </li>
                        <li>
                            Indien het opgeschorte bedrag niet in een redelijke
                            verhouding staat tot het geconstateerde gebrek,
                            hebben wij het recht rente te vorderen over het
                            teveel opgeschorte bedrag.
                        </li>
                    </ol>

                    <h3>ARTIKEL 19 - GARANTIE DOOR DE INSTALLATEUR</h3>
                    <ol>
                        <li>
                            Installateur garandeert dat eventuele gebreken in
                            het werk die binnen 12 maanden na de oplevering aan
                            de dag treden kosteloos worden verholpen.
                        </li>
                        <li>
                            De garantietermijnen geldt tenzij Installateur en
                            Opdrachtgever een langere garantietermijn
                            overeenkomen.
                        </li>
                        <li>
                            De garantie zoals bedoeld in de voorgaande leden
                            vervalt indien:
                            <ul>
                                <li>
                                    gebreken aan de installatie niet binnen
                                    bekwame tijd nadat ze ontdekt werden of
                                    redelijkerwijs ontdekt hadden kunnen worden
                                    schriftelijk aan Installateur zijn gemeld;
                                </li>
                                <li>
                                    gebreken zijn veroorzaakt door een fout,
                                    onoordeelkundig gebruik of verzuim van
                                    Opdrachtgever die de opdracht heeft gegeven
                                    of zijn rechtsopvolger, danwel door van
                                    buiten komende oorzaken;
                                </li>
                                <li>het gebrek geen gevolg is van het werk;</li>
                                <li>
                                    gedurende de garantietermijn zonder
                                    schriftelijke toestemming van Installateur
                                    aan een derde opdracht is verstrekt van
                                    welke aard dan ook om aan de installatie
                                    voorzieningen te treffen, danwel wanneer
                                    door Opdrachtgever zelf zodanige
                                    voorzieningen zijn getroffen, met dien
                                    verstande dat de garantie niet vervalt als
                                    dat gezien de aard en omvang van de
                                    getroffen voorzieningen niet gerechtvaardigd
                                    is;
                                </li>
                                <li>
                                    gedurende de garantieperiode geen periodiek
                                    onderhoud wordt verricht aan apparatuur die
                                    onderhoud behoeft;
                                </li>
                                <li>
                                    Opdrachtgever niet aan zijn
                                    betalingsverplichtingen heeft voldaan,
                                    tenzij hij gebruik maakt van het recht de
                                    betaling op te schorten, met dien verstande
                                    dat de garantie niet vervalt in het geval
                                    Opdrachtgever geen enkel verwijt kan worden
                                    gemaakt van het niet tijdig betalen.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Opdrachtgever is gehouden het geleverde te (doen)
                            onderzoeken, onmiddellijk op het moment dat de zaken
                            hem ter beschikking worden gesteld respectievelijk
                            de desbetreffende werkzaamheden zijn uitgevoerd.
                            Daarbij behoort Opdrachtgever te onderzoeken of
                            kwaliteit en/of kwantiteit van het geleverde
                            overeenstemt met hetgeen is overeengekomen en
                            voldoet aan de eisen die partijen dienaangaande zijn
                            overeengekomen. Eventuele gebreken dienen binnen 2
                            weken na ontdekking schriftelijk aan Installateur te
                            worden gemeld. De melding dient een zo gedetailleerd
                            mogelijke omschrijving van het gebrek te bevatten,
                            zodat Installateur in staat is adequaat te reageren.
                            Opdrachtgever dient Installateur in de gelegenheid
                            te stellen een klacht te (doen) onderzoeken.
                        </li>
                        <li>
                            Indien Opdrachtgever tijdig reclameert, schort dit
                            zijn betalingsverplichting niet op. Opdrachtgever
                            blijft in dat geval ook gehouden tot afname en
                            betaling van de overigens bestelde zaken, tenzij
                            daaraan geen zelfstandige waarde toekomt.
                        </li>
                        <li>
                            Indien komt vast te staan dat een klacht ongegrond
                            is, dan komen de kosten daardoor ontstaan, daaronder
                            begrepen de onderzoekskosten, aan de zijde van
                            Installateur daardoor gevallen, integraal voor
                            rekening van Opdrachtgever.
                        </li>
                        <li>
                            Het bepaalde in dit artikel laat onverlet de
                            aansprakelijkheid van Installateur op grond van de
                            wet.
                        </li>
                    </ol>

                    <h3>ARTIKEL 20 - VRIJWARING</h3>
                    <ol>
                        <li>
                            Opdrachtgever vrijwaart ons voor eventuele
                            aanspraken van derden, die in verband met de
                            uitvoering van de overeenkomst schade lijden en
                            waarvan de oorzaak aan andere dan aan Installateur
                            toerekenbaar is.
                        </li>
                        <li>
                            Indien Installateur uit dien hoofde door derden
                            mocht worden aangesproken, dan is Opdrachtgever
                            gehouden Installateur zowel buiten als in rechte bij
                            te staan en onverwijld als hetgeen te doen dat hem
                            in dat geval verwacht mag worden. Mocht
                            Opdrachtgever in gebreke blijven in het nemen van
                            adequate maatregelen, dan zijn wij, zonder
                            ingebrekestelling, gerechtigd zelf daartoe over te
                            gaan. Alle kosten en schade aan de zijde van
                            Installateur en derden daardoor ontstaan, komen
                            integraal voor rekening en risico van Opdrachtgever.
                        </li>
                    </ol>

                    <h3>ARTIKEL 21 - TOEPASSELIJK RECHT</h3>
                    <ol>
                        <li>
                            Alle transacties, waarop deze voorwaarden van
                            toepassing zijn, ook in geval van transacties met
                            het buitenland, en de daaruit voortvloeiende
                            rechtsbetrekkingen voor partijen worden bij
                            uitsluiting beheerst door het Nederlands recht,
                            geldend voor het Koninkrijk in Europa.
                        </li>
                        <li>
                            In ieder geval komen partijen overeen, dat als de
                            plaats van uitvoering van alle uit deze voorwaarden
                            voortvloeiende verbintenissen de plaats onzer
                            vestiging zal gelden.
                        </li>
                        <li>
                            De titels van de onderscheiden artikelen van deze
                            voorwaarden zijn slechts als hulpmiddel bedoeld en
                            hebben geen betekenis voor de interpretatie van die
                            artikelen.
                        </li>
                    </ol>

                    <h3>ARTIKEL 22 - GESCHILLEN</h3>
                    <p>
                        Alle geschillen tussen partijen, ook die welke slechts
                        door een der partijen als zodanig worden beschouwd,
                        zullen worden beslecht door de bevoegde rechter van de
                        plaats onzer vestiging, thans zijnde Amsterdam,
                        onverminderd ons recht om de wettelijk of bij verdrag
                        bevoegde rechter te kiezen.
                    </p>
                    <h3>ARTIKEL 23 - CONVERSIE</h3>
                    <ol>
                        <li>
                            Indien enig in deze voorwaarden genoemd, en voor
                            Opdrachtgever toepasselijk, beding nietig wordt
                            verklaard, dan zal dit beding worden vervangen door
                            een geldig beding, dat zoveel mogelijk aan dezelfde
                            strekking beantwoordt. De geldigheid van de overige
                            bepalingen van deze voorwaarden blijft ongewijzigd.
                        </li>
                        <li>
                            In geval van niet, niet tijdige, niet volledige of
                            niet goede nakoming hoe ook, is onze plicht tot
                            vergoeding van schade volledig voldaan door alsnog
                            nakoming van hetgeen door de opdrachtgever is
                            bedongen. Voor het geval alsnog nakoming onzerzijds
                            niet meer mogelijk is, is onze plicht tot vergoeding
                            van schade volledig voldaan door betaling van de
                            werkelijk door Opdrachtgever gemaakte onkosten tot
                            een maximum van 25% van het factuurbedrag van de
                            transactie.
                        </li>
                    </ol>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="bg-site-primary border-0"
                    onClick={termsToggleModal}
                >
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default TermsAndConditionModal;
