import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function PrivacyPolicyModal({ privacyToggleModal, privacyIsOpen }) {
    return (
        <Modal
            isOpen={privacyIsOpen}
            toggle={privacyToggleModal}
            centered
            size="lg"
            className="bg-white"
        >
            <ModalHeader toggle={privacyToggleModal}>
                <h2 className="mb-3">Privacyverklaring</h2>
            </ModalHeader>
            <ModalBody className="modal-scrollable">
                <div className="scrollable-content">
                    <p>
                        Wij beschermen jouw persoonlijke gegevens goed. De
                        Energiebespaarders respecteert jouw privacy en
                        persoonlijke levenssfeer, maar De Energiebespaarders
                        heeft soms jouw persoonlijke gegevens nodig. Wij gaan
                        zorgvuldig om met jouw gegevens en voldoen hierbij aan
                        de Algemene verordening gegevensbescherming (AVG). In
                        deze verklaring lees je hoe wij jouw gegevens opslaan,
                        beschermen en verwerken.
                    </p>
                    <h2 className="mb-3">Veilige gegevensoverdacht</h2>
                    <p>
                        Wij beveiligen alle persoonsgegevens die we van jou
                        ontvangen. Met de Secure Socket Layer (SSL) nemen wij de
                        best mogelijke maatregelen tegen verlies, vernietiging,
                        gebruik, wijziging of verspreiding van jouw gegevens
                        door onbevoegden. Het komt voor dat andere partijen jouw
                        gegevens van ons krijgen. Wij komen dan met deze
                        partijen overeen dat zij zorgvuldig met jouw gegevens
                        omgaan. Deze partijen gebruiken jouw gegevens alleen in
                        overeenstemming met het doel waarmee wij de gegevens van
                        je hebben gekregen.
                    </p>
                    <br />
                    <h2 className="mb-3">
                        Gegevensregistratie en algemeen gebruik
                    </h2>
                    <h6 className="mb-2">ARTIKEL 1 BESTELLING</h6>
                    <p>
                        Voor onze dienstverlening hebben we jouw naam,
                        e-mailadres, adres(sen), postcode, woonplaats en
                        telefoonnummer nodig. Daarnaast hebben we de afmetingen
                        en specificaties van jouw woning en situatie (i.e.
                        energienota en planning) nodig. Wij zullen deze gegevens
                        en gegevens over betalingen registreren, opslaan en
                        verwerken voor een juiste afwikkeling van jouw
                        bestelling. Daarnaast kunnen deze gegevens gebruikt
                        worden voor een eventuele latere garantie. Bij de
                        afwikkeling van je bestelling verstrekken wij aan de
                        uitvoerders van installatieopdrachten noodzakelijke
                        persoonlijke gegevens.
                    </p>
                    <br />
                    <h6 className="mb-2">ARTIKEL 2 LOGIN</h6>
                    <p>
                        In jouw account slaan we de volgende informatie op:
                        gebruikersnaam, wachtwoord, naam, adres, woonplaats en
                        wensen met betrekking tot energiebesparende maatregelen.
                        Zo hoef je deze gegevens niet opnieuw in te voeren. We
                        bewaren ook – voor het gebruiksgemak van je eigen
                        account – de gegevens van eerdere bestellingen. Je
                        wachtwoord is volledig versleuteld en kan door niemand
                        ingezien worden.
                    </p>
                    <br />
                    <h6 className="mb-2">
                        ARTIKEL 3 ONDERZOEKS- EN RECLAMEDOELEINDEN
                    </h6>
                    <p>
                        De Energiebespaarders kan jouw gegevens gebruiken voor
                        onderzoeks- en reclamedoeleinden. Dit betekent
                        bijvoorbeld dat wij je informatie sturen over producten
                        uit ons aanbod. Wij maken hierbij gebruik van
                        e-mailreclame voor zover je ons hiertoe uitdrukkelijk
                        hebt gemachtigd. Mochten we vragen om aanvullende
                        persoonsgegevens, zijn deze optioneel, tenzij anders
                        aangegeven. Met deze gegevens verbeteren wij de
                        communicatie richting de mensen die de stap willen
                        zetten richting duurzaam wonen. Voorbeelden hiervan zijn
                        algemene persoonsgegevens als leeftijd, beroep, hobby’s
                        en interesses. Wij gebruiken de gegevens uitsluitend
                        voor het uitvoeren van statistisch onderzoek dat aan de
                        communicatie ten grondslag ligt, en hierbij zullen de
                        aanvullende gegevens worden geanonimiseerd.
                    </p>
                    <br />
                    <h2 className="mb-3">Cookies</h2>
                    <h6 className="mb-2">ARTIKEL 4 WAT ZIJN COOKIES?</h6>
                    <p>
                        Een cookie is een klein tekst bestand dat via de server
                        van een site naar de browser wordt gestuurd. De browser
                        slaat het bestandje vervolgens op op jouw device. Jouw
                        device krijgt een uniek nummer, waarmee onze site het
                        apparaat later weer herkent. We onderscheiden in
                        beginsel sessie-cookies en de tijdelijke of permanente
                        cookies. Sessie cookies worden gewist zodra je de
                        browser weer afsluit. De tijdelijke of permanente
                        cookies blijven een lange tijd of onbeperkt op je device
                        staan. Door je instellingen en je gegevens kunnen wij
                        onze website verbeteren en informatie en aanbiedingen
                        personaliseren.
                    </p>
                    <h6 className="mb-2">
                        ARTIKEL 5 WELKE COOKIES WORDEN DOOR DE
                        ENERGIEBESPAARDERS GEBRUIKT?
                    </h6>
                    <p>
                        Sessie cookies, tijdelijke en permanente cookies. De
                        meeste door ons gebruikte cookies worden na beëindiging
                        van de browsersessie weer van jouw harde schijf
                        verwijderd. Sessie-cookies zijn bijvoorbeeld nodig om je
                        via meerdere pagina's de functie winkelmandje te kunnen
                        aanbieden. Naast deze sessie cookies gebruiken wij
                        cookies die op je computer blijven staan. Als je weer
                        opnieuw gaat browsen wordt je door onze site 'herkend'.
                        Zo weet De Energiebespaarders wat jouw instellingen zijn
                        en welke pagina's je bezocht hebt.
                    </p>
                    <h6 className="mb-2">
                        ARTIKEL 6 WAARVOOR WORDEN COOKIES GEBRUIKT?
                    </h6>
                    <p>Door de opslag van cookies, zorgen we ervoor dat:</p>
                    <div className="d-flex mb-2">
                        <i className="fas fa-check my-auto text-site-primary"></i>
                        <p className="mb-0 ml-2 ">
                            de gebruiker ingelogd blijft;
                        </p>
                    </div>{" "}
                    <div className="d-flex mb-2">
                        <i className="fas fa-check my-auto text-site-primary"></i>
                        <p className="mb-0 ml-2 ">
                            de gebruiker een snelle website ervaart;
                        </p>
                    </div>
                    <div className="d-flex mb-2">
                        <i className="fas fa-check my-auto text-site-primary"></i>
                        <p className="mb-0 ml-2 ">
                            de gebruiker relevante informatie krijgt;
                        </p>
                    </div>{" "}
                    <div className="d-flex mb-2">
                        <i className="fas fa-check my-auto  text-site-primary"></i>
                        <p className="mb-0 ml-2 ">
                            fouten en ongemak op de website opgespoord worden;
                        </p>
                    </div>
                    <p>verbeteringen getest kunnen worden.</p>
                    <h6 className="mb-2">ARTIKEL 7 RETARGETING</h6>
                    <p>
                        Wij maken voor onze website gebruik van retargeting. Met
                        deze techniek bieden wij reclame-uitingen aan op de
                        websites van partners (o.a. Facebook). Dit doen we bij
                        internetgebruikers die al eerder interesse in onze
                        website en onze producten toonden. De weergave van deze
                        reclame-uitingen op pagina's van partners gebeurt op
                        basis van een cookie en een analyse van eerder
                        gebruiksgedrag. Deze cookies zijn volledig anoniem. Er
                        worden geen privacy gerelateerde gegevens opgeslagen.
                    </p>
                    <h6 className="mb-2">
                        ARTIKEL 8 COOKIES VERWIJDEREN OF UITSCHAKELEN
                    </h6>
                    <p>
                        Cookies kun je altijd zelf via de browserinstellingen
                        verwijderen of uitschakelen. Je ontvangt dan geen
                        cookies meer tijdens het bezoeken van onze website.
                        Uitleg over de aanpassing van cookie-instellingen vind
                        je onder Help in de meeste browsers. Maar let wel op:
                        zonder cookies werkt onze site minder goed
                    </p>
                    <h6 className="mb-2">
                        ARTIKEL 9 INZAGE EN WIJZIGEN VAN JOUW GEGEVENS
                    </h6>
                    <p>
                        Voor vragen over ons privacybeleid kun je contact met
                        ons opnemen. Je kan jouw persoonsgegevens inzien en
                        (laten) wijzigen of verwijderen. Je kunt ook jouw
                        profielinformatie veranderen in je persoonlijke
                        accountomgeving van onze website.
                    </p>{" "}
                    <h6 className="mb-2">
                        ARTIKEL 10 WIJZIGINGEN IN DEZE PRIVACYVERKLARING
                    </h6>
                    <p>
                        De Energiebespaarders kan dit privacybeleid aanpassen.
                        Wij zullen gebruikers die met hun e-mailadres bij ons
                        geregistreerd staan op de hoogte brengen bij
                        wijzigingen. Als je niet geregistreerd staat als
                        gebruiker raden wij je aan deze verklaring regelmatig te
                        raadplegen. Bij vragen of opmerkingen over ons privacy
                        statement kun je contact met ons opnemen.
                    </p>{" "}
                    <h6 className="mb-2">ARTIKEL 11 HERROEPING EN BEZWAAR</h6>
                    <p>
                        Je kunt bezwaar maken tegen het gebruik van jouw
                        gegevens voor onze producten en diensten. De
                        mogelijkheid van herroeping geldt voor de toestemming
                        van uitdrukkelijke gegevensverstrekking.
                    </p>{" "}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="bg-site-primary border-0"
                    onClick={privacyToggleModal}
                >
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    );
}

export default PrivacyPolicyModal;
