// Root Reducer

import { combineReducers } from "redux";
import Engergiebespaarders from "./EngergiebespaardersReducer";

export let rootReducer = combineReducers({
    engergie: Engergiebespaarders,
});

export default rootReducer;
