import axios from "axios";
const baseDomain = "https://api.pro6pp.nl/v2/suggest/nl";

const baseURL = `${baseDomain}/`;
let axiosObj1;
axiosObj1 = axios.create({
    baseURL,
    headers: {
        Authorization: `********`,
    },
});
export default axiosObj1;
