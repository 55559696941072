import GetAddressAction from "./GetAddressAction";
import LeadSubmissionRepository from "./LeadSubmissionRepository";

const repositories = {
    address: GetAddressAction,
    leads: LeadSubmissionRepository,
};

export const RepositoryFactory = {
    get: (name) => repositories[name],
};
