import Footer from "../components/Footer";
import Header from "../components/Header/Header";

const Main = (props) => {
    return (
        <>
            <Header />
            <div className="d-flex flex-column " style={{ minHeight: "74vh" }}>
                <div style={{ flexGrow: 2 }}>{props.children}</div>
                {/* <Footer paddingBottom="pb-3" /> */}
            </div>
            <Footer />
        </>
    );
};

export default Main;
