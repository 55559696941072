import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import ArrowLeft from "../../assets/img/Laag50.png";
import Laag44 from "../../assets/img/Laag44.jpg";
import PromotionBadge from "../../assets/img/Gratis.png";

import FormSection from "./FormSection";
import moment from "moment/moment";
import "moment/locale/nl";
import { form1EfficiencyPoints } from "../common/data";
moment.locale("nl");

const HeroSection = () => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
    const date = moment().add(14, "days").format("D MMMM");

    return (
        <Container className="mt-5 my-4">
            <Row>
                <Col md={6} className="hero-section ">
                    <h1 className="fw--700  text-site-black">
                        Bespaar tot wel duizenden euro’s met isoleren, opwekken
                        of verwarmen. Hoge subsidies beschikbaar!
                    </h1>
                    <p className="mt-3">
                        {/* <strong>Hoe?</strong> Energiebespaarders koppelt m.b.v.
                        slimme technieken woningeigenaren aan de beste lokale
                        installateurs. */}
                        Energiebespaarders helpt woningeigenaren met de beste
                        lokale specialisten bij het verduurzamen van hun
                        woningen!
                    </p>
                    {form1EfficiencyPoints?.map((point) => {
                        return (
                            <div className="d-flex align-items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="0.8em"
                                    viewBox="0 0 600 512"
                                >
                                    <path
                                        fill="#10ccbb"
                                        d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                                    />
                                </svg>

                                <span className="ml-2 fs--13 ">{point}</span>
                            </div>
                        );
                    })}
                    {/* <p className="fs--12 mb-1 mt-2">
                        {" "}
                        Extra besparen op kosten met onze expertise en
                        aangesloten specialisten
                    </p>
                    <p className="fs--12 ">Drievoudige garantie</p> */}
                    <p className="mt-3">
                        {/* Ontvang nu gratis en vrijblijvend offertes en advies op
                        maat!{" "} */}
                        <strong>
                            Actie! gratis woninganalyse t.w.v. €99,-
                        </strong>
                    </p>
                    <p>Geldig t/m {date}</p>
                    <div className="position-relative">
                        <p className="fw--500 text-site-secondary my-4">
                            Doe nu de gratis bespaarcheck en check of jouw
                            woning in aanmerking komt. Ontvang gratis
                            onafhankelijk advies en de scherpste offertes.
                        </p>
                        <img
                            alt=""
                            src={ArrowLeft}
                            className="hero-section__arrow-right d-lg-block d-none"
                        ></img>
                    </div>
                    <button
                        className="bg-site-secondary text-white border border-none px-5 py-3 rounded-sm"
                        onClick={() => toggle()}
                    >
                        {" "}
                        <div className="d-flex">
                            {" "}
                            <p className="mb-0 fw--500">
                                Gratis Bespaarcheck
                            </p>{" "}
                            <i className="fas fa-arrow-right mt-1 ml-2"></i>
                        </div>
                    </button>{" "}
                    <div className="position-relative">
                        <p className="fs--14 ml-3  mt-2">
                            Klaar in 1 minuut. Gratis!
                        </p>
                        <img
                            alt=""
                            src={PromotionBadge}
                            className="hero-section__PromotionBadge-right d-sm-none d-block"
                        ></img>
                    </div>
                </Col>
                <Col className="d-sm-block d-none">
                    <div className="d-flex justify-content-end mb-md-0 mb-5 ">
                        <div className="position-relative ">
                            {" "}
                            <img
                                alt=""
                                src={Laag44}
                                className="hero-section__hero-image img-fluid"
                            ></img>{" "}
                            <div>
                                <img
                                    alt=""
                                    src={PromotionBadge}
                                    className="hero-section__promotion-badge img-fluid"
                                ></img>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <FormSection modal={modal} toggle={toggle}></FormSection>
        </Container>
    );
};

export default HeroSection;
