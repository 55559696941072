import React from "react";
import { Col, Container, Row } from "reactstrap";
import TitleLogo from "../../assets/img/titleLogo.png";
import Tenant from "../../assets/img/tenant-logo.png";

const Header = () => {
    return (
        <Container className=" border-bottom border-site-primary">
            <Row className="py-3">
                <Col>
                    <div>
                        <img
                            src={
                                "https://energiebespaarders.nl/_next/static/images/logo_WEB-f89efa3d9c223acf9f1788ac5cad9448.svg"
                            }
                            className="img-fluid"
                            alt=""
                        ></img>
                    </div>
                </Col>
                <Col>
                    <div className="d-flex justify-content-end h-100 w-100">
                        {" "}
                        <div className="d-md-block d-none ">
                            <img
                                src={Tenant}
                                className="img-fluid"
                                alt=""
                            ></img>
                        </div>
                        <div className="d-flex flex-column justify-content-end ml-3 ">
                            <span className="fs--13 fw--600 mb-0">
                                Totaal score 8,6
                            </span>
                            <span className="fs--13 mb-2">
                                326 beoordelingen
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;
