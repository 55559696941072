import React, { useEffect, useState } from "react";
import { Container, Form, Modal, Spinner } from "reactstrap";
import MultipleChoiceQuestions from "../FormSteps/MultipleChoiceQuestions";

import {
    choiceQuestion,
    emailRegex,
    houseRegex,
    nameRegex,
    optionCode,
    phoneRegex,
    zipRegex,
} from "../common/data";
import { useDispatch, useSelector } from "react-redux";
import {
    getAddress,
    postLead,
} from "../../store/actions/EngergiebespaardersAction";
import { useHistory, useLocation } from "react-router-dom";
import Form1 from "../FormSteps/Form1";
import Address from "../FormSteps/Address";
import Form2 from "../FormSteps/Form2";
import TermsAndConditionModal from "../Modals/TermsAndConditionModal";
import PrivacyPolicyModal from "../Modals/PrivacyPolicyModal";
import HomeOwnerAssociationModal from "../common/HomeOwnerAssociationModal";

function FormSection(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation().search;

    let { loader } = useSelector((state) => state.engergie);

    const publisher_id = new URLSearchParams(location).get("clickid");
    const site_subid = new URLSearchParams(location).get("site");

    const formDataInitialState = {
        language: "nl_NL",
        publisher_id,
        site_subid,
        firstname: "",
        lastname: "",
        email: "",
        street: "",
        phone_number: "",
        house_number: "",
        zip: "",
        answers: ["4289"],
        site_custom_url:
            "https://energiebespaarders.verbeterthuis.nl/woninganalyse",
        site_custom_name: "energiebespaarders_woningsanalyse",
        gender: "",
    };
    const [formSteps, setFormSteps] = useState(0);
    const [formData, setFormData] = useState(formDataInitialState);
    const [isAgreed, setIsAgreed] = useState(true);

    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");
    const [error, setError] = useState(false);
    const [selectedOption, setSelectedOption] = useState();
    const [termsAndConditionIsOpen, setTermsAndConditionIsOpen] =
        useState(false);
    const [privacyIsOpen, setPrivacyIsOpen] = useState(false);
    const [homeAssociationModal, setHomeAssociationModal] = useState(false);

    const termsToggleModal = () => {
        setTermsAndConditionIsOpen(!termsAndConditionIsOpen);
    };
    const homeAssociationToggle = () => {
        setHomeAssociationModal(!homeAssociationModal);
    };

    const privacyToggleModal = () => {
        setPrivacyIsOpen(!privacyIsOpen);
    };
    const steps = {
        0: Form1,
        1: Address,
        2: MultipleChoiceQuestions,
        3: MultipleChoiceQuestions,
        4: MultipleChoiceQuestions,
        5: MultipleChoiceQuestions,
        6: Form2,
    };
    const FormPage = steps[formSteps];

    const getCurrentChoiceData = () => choiceQuestion[formSteps];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return { ...prevData, [name]: value };
        });
    };

    const handleButtonClick = (event) => {
        event.preventDefault();

        if (formData.answers.includes("Ja") && formSteps === 4) {
            // history.push("/badankt", { isAssociationOwners: true });
            props.toggle();
            setHomeAssociationModal(true);
            return;
        }

        if (formSteps === 0) {
            if (
                zipRegex.test(formData?.zip) &&
                houseRegex.test(formData?.house_number.toString())
            ) {
                dispatch(
                    getAddress(
                        formData?.zip,
                        formData.house_number,
                        (res) => {
                            if (!res.lat && !res.lng) {
                                setAddressError(
                                    "Combinatie komt niet overeen !"
                                );
                            } else {
                                setAddress(res);
                                setAddressError("");
                                setFormData((prevData) => {
                                    return {
                                        ...prevData,
                                        street: res?.street,
                                        city: res?.municipality,
                                    };
                                });
                                setFormSteps((prev) => Number(prev) + 1);
                            }
                        },
                        () => {
                            setAddressError("Combinatie komt niet overeen !");
                        }
                    )
                );
            }
        } else if (formSteps >= 2 && formSteps <= 5) {
            const currentQuestionAns = getCurrentChoiceData();
            if (
                !currentQuestionAns?.options?.some((item) =>
                    formData.answers.includes(item)
                )
            ) {
                setError(true);
            } else {
                setFormSteps((prev) => Number(prev) + 1);
            }
        } else if (formSteps === 6) {
            if (
                nameRegex.test(formData?.firstname) &&
                nameRegex.test(formData?.lastname) &&
                emailRegex.test(formData?.email) &&
                phoneRegex.test(formData?.phone_number)
            ) {
                const answers = formData.answers;
                const updatedArray = answers.map((item) => {
                    const key = Object.keys(optionCode).find(
                        (code) => optionCode[code] === item
                    );
                    return key ? key : item;
                });
                console.log("Form Data", {
                    ...formData,
                    answers: updatedArray,
                });
                dispatch(
                    postLead({ ...formData, answers: updatedArray }, () => {
                        // const isAssociationOwners =
                        //     updatedArray.includes("4265");
                        // history.push("/badankt", {
                        //     isAssociationOwners: false,
                        // });
                        history.push("/badankt");
                        setFormData(0);
                        setFormData(formDataInitialState);
                    })
                );
            }
        } else {
            setFormSteps((prev) => Number(prev) + 1);
        }
    };
    const getButtonText = () => {
        if (loader) {
            return <Spinner size="sm" />;
        } else if (formSteps === 0) {
            return "Woninggegevens ophalen";
        } else if (formSteps === 6) {
            return "Afronden";
        } else {
            return "Volgende";
        }
    };
    useEffect(() => {
        if (selectedOption) {
            setError(false);
        }
    }, [selectedOption]);
    return (
        <>
            <Modal isOpen={props.modal} toggle={props.toggle} centered>
                {formSteps !== 6 && (
                    <div className="w-75 text-white text-center mx-auto ">
                        <h1>Bespaarcheck</h1>
                        <p>
                            Beantwoord onderstaande vragen en<br></br> ontdek de
                            mogelijkheden voor jouw woning
                        </p>
                    </div>
                )}

                <Container className="pb-3 px-5 text-center bg-white border-bottom-setting overflow-auto  form-setting ">
                    {formSteps !== 6 && (
                        <div className="w-100 border-bottom-setting text-center text-site-black d-flex justify-content-between ">
                            {formSteps === 0 ? (
                                <p></p>
                            ) : (
                                <i
                                    class="fas fa-chevron-left my-auto cursor-pointer"
                                    onClick={() => {
                                        setFormSteps(
                                            (prev) => Number(prev) - 1
                                        );
                                    }}
                                ></i>
                            )}
                            <h6 className="fw--500 my-3">
                                {formSteps === 0
                                    ? "Vraag 1 van 5"
                                    : formSteps === 1
                                    ? "Jouw adresgegevens"
                                    : ` Vraag ${formSteps} van 5`}
                            </h6>{" "}
                            <p></p>
                        </div>
                    )}
                    <Form
                        onSubmit={handleButtonClick}
                        className="text-site-black"
                    >
                        <FormPage
                            data={getCurrentChoiceData()}
                            formData={formData}
                            setFormData={setFormData}
                            handleInputChange={handleInputChange}
                            setAddressError={setAddressError}
                            address={address}
                            addressError={addressError}
                            setSelectedOption={setSelectedOption}
                            selectedOption={selectedOption}
                            error={error}
                            formSteps={formSteps}
                            setIsAgreed={setIsAgreed}
                            isAgreed={isAgreed}
                            setFormSteps={setFormSteps}
                        />
                        <button
                            disabled={loader || !isAgreed}
                            type="submit"
                            className="bg-site-secondary text-white border border-none w-75 px-4 py-3 rounded-sm my-4"
                        >
                            {" "}
                            <div className="d-flex justify-content-between w-100">
                                {" "}
                                <p></p>
                                {getButtonText()}
                                <i className="fas fa-arrow-right mt-1 ml-2"></i>
                            </div>
                        </button>{" "}
                    </Form>
                    {formSteps === 6 && (
                        <p className="fs--600 fs--12">
                            Wij gebruiken jouw gegevens alleen voor het geven
                            van advies op maat voor jouw woning. Lees hier onze{" "}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={termsToggleModal}
                            >
                                algemene voorwaarden
                            </span>{" "}
                            en{" "}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={privacyToggleModal}
                            >
                                {" "}
                                privacystatement
                            </span>
                            .
                        </p>
                    )}
                    <TermsAndConditionModal
                        termsAndConditionIsOpen={termsAndConditionIsOpen}
                        termsToggleModal={termsToggleModal}
                    />
                    <PrivacyPolicyModal
                        privacyIsOpen={privacyIsOpen}
                        privacyToggleModal={privacyToggleModal}
                    />
                </Container>
            </Modal>
            <HomeOwnerAssociationModal
                isOpen={homeAssociationModal}
                toggle={homeAssociationToggle}
            />
        </>
    );
}

export default FormSection;
