const initialState = {
    // straats: [],
    create_response: null,
    loader: false,
};

export default function ziekenfondsenReducer(
    state = initialState,
    { type, payload }
) {
    switch (type) {
        // case "NEW_STRAATS":
        //     return {
        //         ...state,
        //         straats: payload,
        //     };
        // case "LEAD_RESPONSE_LOADER":
        //     return {
        //         ...state,
        //         loader: payload,
        //     };
        case "CREATE_RESPONSE":
            return {
                ...state,
                create_response: payload,
            };
        case "LOADER":
            return {
                ...state,
                loader: payload,
            };

        default:
            return { ...state };
    }
}
