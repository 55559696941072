import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import TermsAndConditionModal from "./Modals/TermsAndConditionModal";
import PrivacyPolicyModal from "./Modals/PrivacyPolicyModal";

const Footer = () => {
    const [termsAndConditionIsOpen, setTermsAndConditionIsOpen] =
        useState(false);
    const [privacyIsOpen, setPrivacyIsOpen] = useState(false);

    const termsToggleModal = () => {
        setTermsAndConditionIsOpen(!termsAndConditionIsOpen);
    };

    const privacyToggleModal = () => {
        setPrivacyIsOpen(!privacyIsOpen);
    };
    return (
        <Container className="border-top border-site-primary py-3">
            <Row>
                <Col>
                    <div className="text-center">
                        <p className="mb-0">
                            De Energiebespaarders © 2023.<br></br>Alle rechten
                            voorbehouden.
                            <br></br>
                            <span
                                className="text-primary cursor-pointer"
                                onClick={termsToggleModal}
                            >
                                Algemene voorwaarden
                            </span>{" "}
                            en{" "}
                            <span
                                className="text-primary cursor-pointer"
                                onClick={privacyToggleModal}
                            >
                                {" "}
                                Privacystatement
                            </span>
                            .
                        </p>
                    </div>
                </Col>
            </Row>
            <TermsAndConditionModal
                termsAndConditionIsOpen={termsAndConditionIsOpen}
                termsToggleModal={termsToggleModal}
            />
            <PrivacyPolicyModal
                privacyIsOpen={privacyIsOpen}
                privacyToggleModal={privacyToggleModal}
            />
        </Container>
    );
};

export default Footer;
